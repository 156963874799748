@import url("https://cdn.web-fonts.ge/fonts/alk-rounded-mtav-med/css/alk-rounded-mtav-med.min.css");
@import url("https://cdn.web-fonts.ge/fonts/bpg-nino-elite-round-cond/css/bpg-nino-elite-round-cond.min.css");
@import url("https://cdn.web-fonts.ge/fonts/arial-geo/css/arial-geo.min.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div {
  font-family: "Arial GEO", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "ALK Rounded Mtav Med", sans-serif;
}

.container {
  width: 100% !important;
}

body {
  width: 100%;
  min-height: 100% !important;
}

.App {
  width: 100%;
  height: 100% !important;
}

div.login {
  width: 500px;
  height: 400px;
  margin: auto;
  border: 1px solid black;
  border-radius: 4.5%;
  box-shadow: 0 0 26px grey;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 50px;
}

.MuiFormControl-root.MuiTextField-root.login-input {
  width: 300px;
  margin-top: 20px;
}

div.header {
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: space-around;
  /* background-color: rgba(159, 216, 237, 0.9); */
}

.fb-link:hover .MuiButton-label{
  color: white;
}

li.header-link {
  list-style-type: none;
}

div.logo {
  padding: 10px;
}

nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

ul.routes {
  display: flex;
  justify-content: space-around;
  width: 92%;
}

img.header-logo {
  width: 100px;
  margin: auto;
}

a {
  text-decoration: none;
}

.MuiButton-label {
  width: auto;
}

img.d-block {
  height: calc(100vh - 125px);
  position: center;
}

img.link {
  width: 300px;
  margin-top: 10px;
}

div.quick-links {
  display: flex;
  justify-content: space-around;
}

div.text-about {
  width: 100%;
  display: flex;
}

div.content {
  font-size: 15px;
}

div.content p {
  text-align: start;
  padding-right: 40px;
}

div.laws {
  font-size: 17px;
}

div.content li {
  text-align: start;
  padding-right: 40px;
}

div.child-links {
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

div.contact {
  padding: 20px;
  margin: auto;
  border: 1px solid black;
  border-radius: 4.5%;
  box-shadow: 0 0 18px grey;
  margin: auto;
  width: 1000px;
}

.mission-background {
  width: 100%;
  height: 500px;
  background-image: url(./components/mission.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.contact-background {
  width: 100%;
  height: 500px;
  background-image: url(./components/contact.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.pupils-background {
  width: 100%;
  height: 500px;
  background-image: url(./components/PUPILS.JPG);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

div.history {
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  font-size: 17px;
  /* background: #dbf3fc; */
}

div.mission {
  padding-left: 100px;
  padding-right: 100px;
  font-size: 17px;
  width: 100%;
}

div.covid-plan {
  width: 100%;
  /* background-color: #01416f; */
  display: flex;
  justify-content: space-around;
  flex-direction:row;
  height: 150px;
  margin-top: 0;
}

div.covid-title {
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  color: white;
}

div.covid-link {
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}

div.about-that {
  padding-left: 100px;
  padding-right: 100px;
}

div.main-text {
  text-align: start;
  padding: 80px;
  font-size: 18px;
}

#curator-feed-default-feed-layout {
  /* width: 1000px; */
  margin: auto;
  padding-top: 70px;
  padding-bottom: 70px;
}

.crt-feed {
  margin: auto;
  display: flex;
  justify-content: space-around;
}

div.facebook {
  background-image: url(./components/water.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.header-link {
  /* margin-top: 40px; */
  font-family: "ALK Rounded Mtav Med", sans-serif;
  margin-top: auto;
  margin-bottom: auto;
}

.header-link a {
  text-decoration: none;
  color: #343a40;
  font-size: 17px;
}

iframe.calendar {
  width: 100%;
  height: 500px;
}

.fon {overflow:hidden;left:-10px;position:absolute;width:100%;height:100%;background:linear-gradient(#000,#002)}
.rock-mountain_1,.rock-mountain_s_1,.rock-mountain_2,.rock-mountain_s_2,.rock-mountain_3,.rock-mountain_s_3 {width:140px; height:140px;background:linear-gradient(90deg,#222,#000);transform:rotate(45deg);position:absolute;}
.rock{position:absolute;bottom:3%;left:40%}
.rock-mountain_1{z-index:8;left:140px;bottom:25px;width:180px; height:180px;}
.rock-mountain_s_1 {z-index:7;transform:rotate(52deg);left:125px;bottom:25px;width:180px; height:180px;background:#111}
.rock-mountain_2 {z-index:11;left:-50px;bottom:30px;width:180px; height:180px;}
.rock-mountain_s_2 {z-index:10;transform:rotate(52deg);left:-65px;bottom:30px;width:180px; height:180px;background:#111}
.rock-mountain_3 {z-index:10;left:60px;bottom:25px;width:180px; height:180px;}
.rock-mountain_s_3 {z-index:9;transform:rotate(52deg);left:45px;bottom:25px;width:180px; height:180px;background:#111}
.horizon div{left:20%;z-index:15;position:absolute;bottom:0;border-radius:100px 100px 0 0; background:linear-gradient(#333,#111);width:200px;height:150px}
.horizon div:nth-child(2){z-index: 15;
left: 48%;
bottom: 0px;
height: 130px;
background:linear-gradient(120deg, #333, #111)}
.horizon div:nth-child(3){z-index:14;background:linear-gradient(#222,#111);left:40%;bottom:0px;height:180px;width:150px}
.horizon div:nth-child(4){z-index:14;background:linear-gradient(190deg,#333,#111);left:38%;bottom:20px;height:170px}
.horizon div:nth-child(5){z-index:15;left:25%;bottom:0px;height:170px;background:linear-gradient(210deg,#333,#111)}
.horizon div:nth-child(6){background:linear-gradient(180deg,#444,#111);z-index:14;left:34%;bottom:0px;height:190px}
.horizon div:nth-child(7){background:linear-gradient(170deg,#333,#111);z-index:13;left:55%;bottom:0;height:140px}
.horizon div:nth-child(8){background:linear-gradient(160deg,#444,#111);z-index:14;left:50%;bottom:0;height:160px}
.horizon div:nth-child(9){background:linear-gradient(170deg,#333,#111);z-index:15;left:37%;bottom:0px;height:160px}
.horizon div:nth-child(10){background:linear-gradient(-210deg,#333,#111);z-index:13;left:55%;bottom:0px;height:160px}
.horizon div:nth-child(11){background:linear-gradient(-180deg,#222,#111);z-index:13;left:67%;bottom:0px;height:130px}
.horizon div:nth-child(12){background:linear-gradient(-180deg,#222,#111);z-index:15;left:70%;bottom:0px;height:100px}
.horizon div:nth-child(13){background:linear-gradient(180deg,#333,#111);z-index:110;left:15%;bottom:0px;height:100px}
.horizon div:nth-child(14){background:linear-gradient(180deg,#333,#111);z-index:110;left:75%;bottom:0px;height:100px}
.horizon div:nth-child(15){background:linear-gradient(180deg,#333,#111);z-index:110;left:60%;bottom:0px;height:130px}
.horizon div:nth-child(16){width:85%;height:600px;border-radius:300px 400px 0 0;background:linear-gradient(130deg,#222,#000);z-index:12;left:12%;bottom:-405px}
.satellite{-webkit-animation:satellit-anima 25s linear infinite;-moz-animation:satellit-anima 25s linear infinite;animation:satellit-anima 25s linear infinite;transform:rotate(120deg);z-index:5;color:#888;font-size:18px;position:absolute;left:10%;bottom:0}

.hill {z-index:14;background:linear-gradient(80deg,#333,#111);position:absolute;bottom:5%;left:28%;width:200px;height:190px;border-radius:100px 100px 0 0;}

.moon-sky {
  -webkit-animation:moon-anim 3s linear alternate-reverse infinite;
  -moz-animation:moon-anim 3s linear alternate-reverse infinite;
  animation:moon-anim 3s linear alternate-reverse infinite ;box-shadow:0 0 15px 5px #fc0;width:100px;height:100px;border-radius:50px;background:#fc0;position:absolute;top:10%;left:5%}
.cosmos-star div{-webkit-animation:star-anim 200ms linear alternate-reverse infinite;
  -moz-animation:star-anim 200ms linear alternate-reverse infinite;
  animation:star-anim 200ms linear alternate-reverse infinite;
position:absolute;height:1px;width:1px;box-shadow:0 0 15px 3px #fff;background:#fff;}
.cosmos-star div:nth-child(1){left:75%;top:10%}
.cosmos-star div:nth-child(2){left:45%;top:12%}
.cosmos-star div:nth-child(3){left:20%;top:12%}
.cosmos-star div:nth-child(4){left:30%;top:18%}
.cosmos-star div:nth-child(5){left:92%;top:17%}
.cosmos-star div:nth-child(6){left:60%;top:5%}
.cosmos-star div:nth-child(7){left:67%;top:16%}
.cosmos-star div:nth-child(8){left:5%;top:30%}

.meteores div{position:absolute;top:50px;left:280px;width:400px;height:1px;transform:rotate(-45deg);
background:linear-gradient(to left,transparent 0%,#fff 100%)}
.meteores div:before{content:'';position:absolute;width:4px;height:5px;background:#fff;
border-radius:50%;box-shadow:0 0 14px 4px white;margin-top:-2px}
.meteores div:nth-child(1){top:45%;left:100%;-webkit-animation:meteors 3s linear infinite;
-moz-animation:meteors 3s linear infinite;animation:meteors 3s linear infinite}
.meteores div:nth-child(2){top:100%;left:70%;-webkit-animation:meteors 4s linear infinite;
-moz-animation:meteors 4s linear infinite;animation:meteors 4s linear infinite}
.meteores div:nth-child(3){top:70%;left:20%;-webkit-animation:meteors 2s linear infinite;
-moz-animation:meteors 2s linear infinite;animation:meteors 2s linear infinite}

.page-404,.not-found{transform:rotate(0deg);font:55px 'Carter One',  cursive; color:#777;position:absolute;display:block;bottom:10%;left:46.5%;z-index:120;text-shadow:2px 2px 2px #000}
.not-found{bottom:3%;left:45%;font-size:40px;}

.my-cat{z-index:100;position:absolute;left:35%;bottom:235px}
.ear-l,.ear-r,.ear-fur-l,.ear-fur-r{position:relative;z-index:2;border-radius:0 50px 0 0px;width:12px;height:14px;margin:0px 0 -16px 0px;padding:2px;transform:rotate(-2deg);background:linear-gradient(40deg,#111,#444)}
.ear-r,.ear-fur-r{border-radius:50px 0px 10px 0px;margin:0 25px -10px;transform:rotate(15deg);background:linear-gradient(-50deg,#333,#333,#111);}
.ear-fur-l,.ear-fur-r{border-radius:0 50px 0 20px;padding:0;background:linear-gradient(-30deg,#111,#222);width:10px;height:14px}
.ear-fur-r{margin:0 2px;background:linear-gradient(-290deg,#111,#222);transform:rotate(8deg);border-radius:50px 0px 20px}
.head-cat{z-index:1;position:relative;border-radius:50px;width:40px;height:35px;background:linear-gradient(40deg,#000,#444);box-shadow:0 2px 1px #111}
.tabby-cat,.tabby-cat-1,.tabby-cat-2{width:20px;height:2px; background:#222;position:absolute;margin:2px 10px}
.tabby-cat-1{margin:6px 10px;background:#222}
.tabby-cat-2{height:2px;margin:10px 17px;background:linear-gradient(#111,#222);width:6px}
.muzzle-cat{width:22px;height:15px;background:linear-gradient(60deg,#111, #222);border-radius:50px;top:18px;left:11px;position:absolute}
.whiskers div,.whiskers div:nth-child(3) {height:1px;width:16px;background:linear-gradient(90deg,#000,#555);position:absolute;left:-7px;top:31px;transform:rotate(-15deg)}
.whiskers div:nth-child(1){top:28px;left:-5px;transform:rotate(-5deg)}
.whiskers div:nth-child(2){width:17px;top:35px;left:-5px;transform:rotate(-25deg)}
.whiskers div:nth-child(3),.whiskers div:nth-child(4),.whiskers div:nth-child(5){background:linear-gradient(90deg,#555,#000);transform:rotate(10deg);left:30px;top:31px}
.whiskers div:nth-child(4){left:29px;top:28px;transform:rotate(-2deg);width:20px;}
.whiskers div:nth-child(5){left:27px;top:34px}
.jaws-cat{-webkit-animation:jaws-cat 5s infinite;
-moz-animation:jaws-cat 5s infinite;
  animation:jaws-cat 5s infinite;position:absolute;border-radius:30px 10px;position:absolute;border-radius:20px 20px 70px 70px;width:7px;height:3px;background:linear-gradient(#000,#d46);z-index:50;top:30px;left:17px}
.eye-l,.eye-r{
  -webkit-animation:sleep-cat 7s linear infinite;
-moz-animation:sleep-cat 7s linear infinite;
  animation:sleep-cat 7s linear infinite;position:absolute;border-radius:35px 25px 30px 30px;width:10px;height:7px;background:#df9;z-index:500;top:12px;left:7px}
.eye-r{left:24px!important}
.eye-lz,.eye-rz{
  -webkit-animation:eye-cat 7s linear infinite;
-moz-animation:eye-cat 7s linear infinite;
  animation:eye-cat 7s linear infinite;position:absolute;border-radius:30px 10px;transform:rotate(-45deg);width:7px;height:6px;background:#000;z-index:500;left:2px;top:0px}
.eye-rz{left:1px;}
.cat-nose{position:absolute;border-radius:20px 20px 70px 70px;width:7px;height:7px;background:linear-gradient(#000,#555);z-index:500;margin:19px 16px}
.body-cat{transform:rotate(2deg)}
.body-cat,.body-cat-1 {width:30px;height:60px;border-radius:70px 0px 5px 35px;margin:-10px -7px;background:linear-gradient(120deg,#000,#111,#222);position:absolute}
.body-cat-1{border-radius:25px 80px 25px 25px;height:65px;margin:-14px 6px}
.paw-cat-l,.paw-cat-r {position:absolute;width:12px;height:46px;margin:5px 4px;background:linear-gradient(150deg,rgba(1,1,1,.4) ,rgba(34,34,34,.8)),linear-gradient(92deg,#111 3px,#222 70%,#111 );box-shadow:-5px -2px 5px #111;border-radius:30px 0 5px 15px}
.paw-cat-r{margin:5px 22px;border-radius:0px 30px 15px 5px;background:linear-gradient(rgba(1,1,1,.4) ,rgba(34,34,34,.8)),linear-gradient(90deg,#111,#222 70%,#111);}
.paw-cat-l div,.paw-cat-r div,.tail-cat {border-radius:70px 10px 70px 10px;background:linear-gradient(#111,#222);position:absolute;width:12px;height:7px;top:38px;left:1px}
.paw-cat-r div {border-radius:10px 70px 10px 70px;}
.tail-cat{transform:rotate(-15deg);top:80px;width:40px;height:13px;border-radius:40px;background:linear-gradient(60deg,#111,#222)}

@-webkit-keyframes eye-cat {0%{left:3px} 10%{border-radius:30px} 20%{border-radius:30px;} 30% {left:1px} 40%{top:2px;right:2px} 50%{left:4px} 60%{bottom:2px} 70%{bottom:0px;width:0} 71%{width:5px} 80%{left:2px}97%{bottom:1px;width:0} 100%{bottom:2px}}
@-moz-keyframes eye-cat {0%{left:3px} 10%{border-radius:30px} 20%{border-radius:30px;} 30% {left:1px} 40%{top:2px;right:2px} 50%{left:4px} 60%{bottom:2px} 70%{bottom:0px;width:0} 71%{width:5px} 80%{left:2px}97%{bottom:1px;width:0} 100%{bottom:2px}}
@keyframes eye-cat 
{0%{left:3px} 10%{border-radius:30px} 20%{border-radius:30px;} 30% {left:1px} 40%{top:2px;right:2px} 50%{left:4px} 60%{bottom:2px} 70%{bottom:0px;width:0} 71%{width:5px} 80%{left:2px}97%{bottom:1px;width:0} 100%{bottom:2px}}
@-webkit-keyframes sleep-cat {0%{height:7px;top:12px;border-radius:30px} 35%{height:8px;top:12px;border-radius:30px} 56%{height:4px;top:13px} 70%{height:3px;top:15px} 71%{height:8px;top:12px} 85%{height:3px;top:12px} 97%{height:0;top:15px}}
@-moz-keyframes sleep-cat {0%{height:7px;top:12px;border-radius:30px} 35%{height:8px;top:12px;border-radius:30px} 56%{height:4px;top:13px} 70%{height:3px;top:15px} 71%{height:8px;top:12px} 85%{height:3px;top:12px} 97%{height:0;top:15px}}
@keyframes sleep-cat 
{0%{height:7px;top:12px;border-radius:30px} 35%{height:8px;top:12px;border-radius:30px} 56%{height:4px;top:13px} 70%{height:3px;top:15px} 71%{height:8px;top:12px} 85%{height:3px;top:12px} 97%{height:0;top:15px}}
@-webkit-keyframes jaws-cat {0%{height:0} 50%{height:3px}100%{height:0}}
@-moz-keyframes jaws-cat {0%{height:0} 50%{height:3px}100%{height:0}}
@keyframes jaws-cat {0%{height:0} 50%{height:3px}100%{height:0}}
@-webkit-keyframes star-anim {0% {box-shadow:0 0 10px 2px #fff;}
  100% {box-shadow:0 0 5px 1px #fff;}}
@-moz-keyframes star-anim {0% {box-shadow:0 0 10px 2px #fff;}
  100% {box-shadow:0 0 5px 1px #fff;}}
@keyframes star-anim {0% {box-shadow:0 0 10px 2px #fff;}
  100% {box-shadow:0 0 5px 1px #fff;}}
@-webkit-keyframes moon-anim {0% {box-shadow:0 0 20px 5px #fc0;}
  100% {box-shadow:0 0 10px 5px #fc0;}}
@-moz-keyframes moon-anim {0% {box-shadow:0 0 20px 5px #fc0;}
  100% {box-shadow:0 0 10px 5px #fc0;}}
@keyframes moon-anim {0% {box-shadow:0 0 20px 5px #fc0;}
  100% {box-shadow:0 0 10px 5px #fc0;}}
@-webkit-keyframes satellit-anima {0% {bottom:0;opacity:0} 2%{opacity:1;transform:rotate(130deg)}15%{transform:rotate(120deg);color:#fff;text-shadow:0 0 8px}
35%{font-size:20px;left:15%;transform:rotate(180deg);}55%{font-size:17px;transform:rotate(185deg);color:#ff8;text-shadow:0 0 8px}57%{font-size:14px;} 100% {font-size:10px;transform:rotate(200deg);bottom:90%;left:90%;opacity:0}}
@-moz-keyframes satellit-anima {0% {bottom:0;opacity:0} 2%{opacity:1;transform:rotate(130deg)}15%{transform:rotate(120deg);color:#fff;text-shadow:0 0 8px}
35%{font-size:20px;left:15%;transform:rotate(180deg);}55%{font-size:17px;transform:rotate(185deg);color:#ff8;text-shadow:0 0 8px}57%{font-size:14px;} 100% {font-size:10px;transform:rotate(200deg);bottom:90%;left:90%;opacity:0}}
@keyframes satellit-anima {0% {bottom:0;opacity:0} 2%{opacity:1;transform:rotate(130deg)}15%{transform:rotate(120deg);color:#fff;text-shadow:0 0 8px}
35%{font-size:20px;left:15%;transform:rotate(180deg);}55%{font-size:17px;transform:rotate(185deg);color:#ff8;text-shadow:0 0 8px}57%{font-size:14px;} 100% {font-size:10px;transform:rotate(200deg);bottom:90%;left:90%;opacity:0}}

@-webkit-keyframes meteors{0%{margin:-300px -300px 0 0;opacity:1}8%{opacity:0}
30% {margin-top:300px -600px 0 0;opacity:0}100% {opacity:0}}
@-moz-keyframes meteors{0%{margin:-300px -300px 0 0;opacity:1}8%{opacity:0}
30% {margin-top:300px -600px 0 0;opacity:0}100% {opacity:0}}
@keyframes meteors{0%{margin:-300px -300px 0 0;opacity:1}8%{opacity:0}
30% {margin-top:300px -600px 0 0;opacity:0}100% {opacity:0}}

#handboy
{
   animation: swing ease-in-out 1.3s infinite alternate;
    transform-origin: 98% 98%;
    transform-box: fill-box;
    
}


#girllight
{
   animation: swing ease-in-out 1.3s infinite alternate;
    transform-origin: 0% 97%;
    transform-box: fill-box;
}

#hairgirl
{
    animation: swinghair ease-in-out 1.3s infinite alternate;
   transform-origin: 60% 0%;
    transform-box: fill-box;
  
}

#zero
{
  transform-origin:bottom;
  transform-box:fill-box;
  
}

/*************swing************/
@keyframes swing {
    0% { transform: rotate(10deg); }
    100% { transform: rotate(-10deg); }
}


/*************swing hair************/
@keyframes swinghair {
    0% { transform: rotate(6deg); }
    100% { transform: rotate(-6deg); }
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s
}

/* Modal Content */
.modal-content {
  position: fixed;
  bottom: 0;
  background-color: #fefefe;
  width: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {padding: 2px 16px;}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {bottom: -300px; opacity: 0} 
  to {bottom: 0; opacity: 1}
}

@keyframes slideIn {
  from {bottom: -300px; opacity: 0}
  to {bottom: 0; opacity: 1}
}

@-webkit-keyframes fadeIn {
  from {opacity: 0} 
  to {opacity: 1}
}

@keyframes fadeIn {
  from {opacity: 0} 
  to {opacity: 1}
}


form.add-post {
  width: 800px;
  text-align: start;
  margin: auto;
}

.single {
  display: flex;
  justify-content: space-around;
  width: 30%;
  height: 200px;
  border: 1px solid black;
  border-radius: 5%;
  box-shadow: 8px 8px 14px rgba(121, 113, 113, 0.41);
  overflow: hidden;
}
.image {
  max-width: 50%;
  height: 100%;
  display: flex;
}

.image img {
  max-width: 100%;
}

div.post-content {
  min-width: 50%;
}

svg.svg-inline--fa.fa-bars.fa-w-14.menu {
  display: none;
}

.responsive-icon {
  display: flex;
  display: none;
}

div.content {
  padding-left: 70px;
  padding-bottom: 30px;
  padding-right: 70px;
}

img.footer-logo {
  width: 200px;
}

.footer {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}

.links {
  display: flex;
  justify-content: space-between;
  text-align: start;
}

.third-column {
  width: calc(100% / 4);
}

.copyright {
  padding-top: 5px;
  padding-bottom: 5px;
}

.svg-inline--fa.fa-facebook.fa-w-16 {
  font-size: 25px;
  color: black;
}

.svg-inline--fa.fa-instagram.fa-w-14 {
  font-size: 25px;
  color: black;
}

.svg-inline--fa.fa-youtube.fa-w-18 {
  font-size: 25px;
  color: black;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6 {
  margin: auto;
  padding-bottom: 20px;
}

.post-container {
  width: 100%;
  display: flex;
}

.feed {
  width: 50%;
}

.single-post {
  width: 50%;
  height: 100%;
}

.first {
  display: flex;
  justify-content: space-evenly;
}

.pupil-card {
  width: 100%;
}

.view-img {
  width: 100% !important;
}

/* .pupil-info {
  padding-top: 50px;
  font-size: 18px;
} */

.card-logo {
  width: calc(100% / 3);
}

.card-logo img {
  width: 100%;
}

.card-code {
  text-align: center;
}
.pupil-info {
  padding-top: 0;
}
.some-warnings {
  text-align: center;
}

.news {
  display: flex;
  justify-content: space-evenly;
}

.other-staff {
  width: 35%;
}

.react-calendar {
  margin: auto;
}

@page {
  width: 100%;
}

div.quick {
  height: 70px;
  width: 75%;
  background-color: rgba(215, 215, 215, 0.35);
  margin: auto;
  margin-top: 15px;
  box-shadow: 0 0 2px #ccc4c4;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

div.quick:hover {
  box-shadow: 9px 13px 26px -8px #222533;
}

.news-container {
  width: 75% !important;
}

.contract {
  padding: 50px;
}

.messages {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 960px) {
  .MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 60% !important;
    flex-basis: 50%;
  }
}
/* @media (min-width: 960px) {
  .MuiGrid-grid-md-6 {
    flex-grow: 0;
    max-width: 70% !important;
    flex-basis: 50%;
  }
} */

/* @media print {
  .contract {
    padding: 25px;
    display: inline-block; 
    width: 100%; 
    page-break-after: always;
  }
  span.contract-span-three {
    padding-top: 20px;
  }
  p {
    color: black
  }
  div {
    color: black
  }
  span {
    color: black
  }
} */

@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .pagebreak {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 10px;
}


@media print {
  .some-warnings {
    text-align: center;
  }

  ul {
    margin-bottom: 6px;
  }

  .small-group {
    font-size: 10px;
  }

  .card- {
    width: 192px;
    height: 256px;
    background-position: center;
  }

  .test-laws h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }

  .pupil-info {
    padding-top: 0;
    padding-right: 30px;
  }

  .first {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  
  .pupil-info {
    padding-left: 30px;
    font-size: 13px;
    width: 55%;
  }

  .pupil-card {
    height: 450px;
    width: 100%;
    padding: 25px
  }
  
  .view-img {
    width: 100% !important;
  }
  
  .card-logo {
    width: calc(100% / 3);
  }
  
  .card-logo img {
    width: 100%;
  }

  .card-code {
    text-align: center;
  }

  img {
    width: 100%;
  }
}

@media screen and (max-width:1075px) {
  ul.routes {
    display: none;
  }

  nav {
    justify-content: space-between;
  }

  svg.svg-inline--fa.fa-bars.fa-w-14.menu {
    font-size: 40px;
    display: block;
  }

  .responsive-icon {
    display: flex;
  }

  .covid-plan {
    display: block !important;
  }

  .covid-link {
    margin-top: 0 !important;
    padding-top: 10px;
  }

  div.covid-title {
    margin-top: 0;
    padding-top: 10px;
  }

  .crt-feed {
    margin: auto;
    display: block;
  }

  div.main-text {
    text-align: start;
    padding: 15px;
  }

  div.mission {
    padding: 15px;
  }

  div.history {
    padding: 15px;
  }

  div.content {
    padding: 10px;
    width: 100%;
  }

  div.about-that {
    padding: 10px;
  }

  div.child-links {
    flex-direction: column;
  }

  div.quick-links {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  img.link {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  div.contact {
    width: 95%;
  }

  div.map iframe {
    width: 95%;
  }

  form.add-post {
    width: 100%;
    padding: 10px;
  }

  .contact-background {
    background-attachment: initial;
  }

  .mission-background {
    background-attachment: initial;
  }

  .pupils-background {
    background-attachment: initial;
  }
}

@media screen and (max-width: 700px) {
  .news {
    display: block;
    margin: auto;
  }

  .news-container {
    width: 100% !important;
    margin: auto;
  }

  .MuiPagination-ul {
    margin: auto !important;
  }

  .other-staff {
    width: 100%;
  }

  .login {
    width: 100% !important;
  }

  .footer {
    flex-direction: column;
  }

  .third-column {
    width: auto;
    display: flex;
    flex-direction: column;
  }

  .links {
    width: auto
  }

  .covid-plan {
    height: 170px !important;
  }

  .contact-background {
    background-attachment: initial;
  }

  .mission-background {
    background-attachment: initial;
  }

  .pupils-background {
    background-attachment: initial;
  }
}
#page-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: #F5F5F5;
}

.three-balls {
  margin: 0 auto;
  width: 70px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
}

.three-balls .ball {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: bouncedelay 2.0s infinite cubic-bezier(.62, .28, .23, .99) both;
  animation: bouncedelay 2.0s infinite cubic-bezier(.62, .28, .23, .99) both;
}

.three-balls .ball1 {
  -webkit-animation-delay: -.25s;
  animation-delay: -.25s;
}

.three-balls .ball2 {
  -webkit-animation-delay: -.12s;
  animation-delay: -.12s;
}

@keyframes bouncedelay {
  0% {
    bottom: 0;
    background-color: #03A9F4;
  }
  16.66% {
    bottom: 40px;
    background-color: #FB6542;
  }
  33.33% {
    bottom: 0px;
    background-color: #FB6542;
  }
  50% {
    bottom: 40px;
    background-color: #FFBB00;
  }
  66.66% {
    bottom: 0px;
    background-color: #FFBB00;
  }
  83.33% {
    bottom: 40px;
    background-color: #03A9F4;
  }
  100% {
    bottom: 0;
    background-color: #03A9F4;
  }
}
.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-around;
  }
@-webkit-keyframes bouncedelay {
  0% {
    bottom: 0;
    background-color: #03A9F4;
  }
  16.66% {
    bottom: 40px;
    background-color: #FB6542;
  }
  33.33% {
    bottom: 0px;
    background-color: #FB6542;
  }
  50% {
    bottom: 40px;
    background-color: #FFBB00;
  }
  66.66% {
    bottom: 0px;
    background-color: #FFBB00;
  }
  83.33% {
    bottom: 40px;
    background-color: #03A9F4;
  }
  100% {
    bottom: 0;
    background-color: #03A9F4;
  }
}